@import "../../../global.scss";

.allNews {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 962px) {
    gap: 0px;
  }
  .headband {
    padding: 30px;
    border-radius: 30px;
    background-color: $color-white01;
    @media (max-width: 962px) {
      aspect-ratio: (319 / 180);
      padding: 16px 12px;
      border-radius: 8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      img {
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
  .content {
    padding: 100px 30px 35px 30px;
    border-radius: 30px;
    background-color: $color-white01;
    @media (max-width: 962px) {
      padding: 16px 12px;
      border-radius: 8px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .buttons {
      padding-bottom: 80px;
      margin-bottom: 70px;
      border-bottom: 1px solid $color-grey02;
      display: flex;
      justify-content: center;
      gap: 20px;
      background-color: $color-white01;
      @media (max-width: 962px) {
        padding-bottom: 24px;
        margin-bottom: 0px;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
      }
      .button {
        width: 215px;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid $color-green01;
        text-align: center;
        color: $color-green01;
        cursor: pointer;
        transition: all 0.5s;
        @media (max-width: 962px) {
          width: 135px;
          padding: 6px;
          border-radius: 4px;
        }
        &.active {
          color: $color-white01;
          background-color: $color-green01;
        }
        &:hover {
          color: $color-white01;
          background-color: $color-green01;
        }
      }
    }
    .newsList {
      margin-bottom: 70px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 962px) {
        gap: 16px;
        margin-bottom: 24px;
      }
    }
    .load {
      display: flex;
      justify-content: center;
    }
  }
}
