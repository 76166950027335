@import "../../../../global.scss";

.map {
  border-radius: 20px;
  padding: 30px;
  background-color: $color-white01;
  @media (max-width: 962px) {
    padding: 16px 12px;
    border-radius: 8px;
  }
  .content {
    border-radius: 15px;
    @media (max-width: 962px) {
      border-radius: 4px;
      overflow-x: scroll;
    }
    .wrap {
      border-radius: 15px;
      width: 100%;
      img {
        object-fit: cover;
      }
      @media (max-width: 962px) {
        width: 150%;
        border-radius: 4px;
        margin-bottom: 8px;
      }
    }
  }
}
