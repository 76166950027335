@import "../../../../global.scss";

.button {
  width: max-content;
  min-width: 229px;
  height: max-content;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  @media (max-width: 962px) {
    min-width: 146px;
    border-radius: 6px;
  }
  &:hover {
    .text {
      background-color: $color-green01;
      color: $color-white01;
    }
    .arrow {
      background-color: $color-green01;
      svg {
        path {
          stroke: $color-white01;
        }
      }
    }
  }

  display: flex;
  justify-content: space-between;
  .text {
    position: relative;
    width: 100%;
    height: 62px;
    padding: 18px;
    border-radius: 8px;
    border-left: 1px solid $color-green01;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-green01;
    transition: all 0.5s;
    @media (max-width: 962px) {
      height: 32px;
      padding: 9px 21px;
      border-radius: 6px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -1px;
      right: 0px;
      height: 8px;
      border: solid 1px $color-green01;
      border-bottom: none;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      @media (max-width: 962px) {
        height: 6px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: -1px;
      right: 0px;
      height: 8px;
      border: solid 1px $color-green01;
      border-top: none;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      @media (max-width: 962px) {
        height: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
  .arrow {
    position: relative;
    height: 62px;
    padding: 18px;
    border-radius: 8px;
    border-right: 1px solid $color-green01;
    transition: all 0.5s;
    @media (max-width: 962px) {
      height: 32px;
      padding: 8px;
      border-radius: 6px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: -1px;
      height: 8px;
      border: solid 1px $color-green01;
      border-bottom: none;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      @media (max-width: 962px) {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: -1px;
      height: 8px;
      border: solid 1px $color-green01;
      border-top: none;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      @media (max-width: 962px) {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
    svg {
      min-width: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      @media (max-width: 962px) {
        min-width: 16px;
        min-height: 16px;
      }
      path {
        transition: stroke 0.5s ease;
      }
    }
  }
}
