@import "../../../../global.scss";

.button {
  width: max-content;
  height: max-content;
  padding: 13px;
  border-radius: 8px;
  border: 1px solid $color-green01;
  display: flex;
  transition: all 0.5s;
  cursor: pointer;
  user-select: none;
  @media (max-width: 962px) {
    padding: 8px;
    border-radius: 6px;
  }
  &:hover {
    background-color: $color-green01;
    .arrow {
      svg {
        path {
          stroke: $color-white01;
        }
      }
    }
  }
  &.active {
    background-color: $color-green01;
    .arrow {
      svg {
        path {
          stroke: $color-white01;
        }
      }
    }
  }
  .arrow {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    @media (max-width: 962px) {
      width: 16px;
      height: 16px;
    }
    svg {
      path {
        transition: stroke 0.5s ease;
      }
    }
  }
}
