@import "../../../global.scss";

.newsSwiper {
  .swiper-wrapper {
    margin-bottom: 10px !important;
    @media (max-width: 962px) {
      margin-bottom: -10px !important;
    }
  }

  .swiper-scrollbar {
    left: 50% !important;
    bottom: 3px !important;
    transform: translate(-50%) !important;
    width: 500px !important;
    opacity: 1 !important;
    height: 1px !important;
    display: flex;
    align-items: center;
    @media (max-width: 962px) {
      width: 150px !important;
    }
  }

  .swiper-scrollbar-drag {
    height: 3px !important;
    background-color: $color-black01 !important;
    @media (max-width: 962px) {
      height: 1px !important;
    }
  }
}
