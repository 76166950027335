@import "../../../../global.scss";

.media {
  padding: 50px 30px;
  border-radius: 20px;
  background-color: $color-white01;
  @media (max-width: 962px) {
    padding: 16px 12px;
    border-radius: 8px;
  }
  .header {
    padding-bottom: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey02;
    @media (max-width: 962px) {
      padding-bottom: 16px;
      margin-bottom: 24px;
    }
    .button {
      padding-top: 8.5px;
      @media (max-width: 962px) {
        padding-top: 0px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 962px) {
      gap: 24px;
    }
  }
}
