@import "../../global.scss";

.footer {
  padding: 40px 30px;
  border-radius: 20px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  background-color: $color-blue02;
  color: $color-white01;
  gap: 15px;
  @media (max-width: 962px) {
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    margin: 16px;
    gap: 32px;
  }
  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 962px) {
      gap: 8px;
    }
    &:last-child {
      gap: 10px;
      justify-content: center;
      @media (max-width: 962px) {
        display: none;
      }
    }
    .contacts {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 962px) {
        font-size: 9px;
        line-height: 10.8px;
        gap: 8px;
      }
    }
    .copyright {
      @media (max-width: 962px) {
        font-size: 9px;
        line-height: 10.8px;
      }
    }
    .socialNetworks {
      display: flex;
      gap: 20px;
      @media (max-width: 962px) {
        gap: 12px;
      }
      img {
        cursor: pointer;
      }
    }
    .mobileRow {
      display: none;
      @media (max-width: 962px) {
        margin-top: 24px;
        display: block;
      }
    }
  }
  .item {
    cursor: pointer;
    color: $color-grey02;
    &:hover {
      color: $color-white01;
    }
  }
}
