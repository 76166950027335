@import "./global.scss";

.App {
  background-color: $color-grey01;
  display: flex;
  justify-content: center;
  .wrap {
    max-width: 1920px;
    width: 100%;
    padding: 20px 40px;
    @media (max-width: 962px) {
      padding: 0px;
    }
  }
}
