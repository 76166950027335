@import "../../../global.scss";

.news {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  color: $color-black01;
  @media (max-width: 1536px) {
    flex-direction: column;
    gap: 20px;
  }
  .content {
    width: 67.93%;
    padding: 30px;
    border-radius: 20px;
    background-color: $color-white01;
    display: flex;
    flex-direction: column;
    @media (max-width: 1536px) {
      width: 100%;
    }
    @media (max-width: 962px) {
      padding: 16px 12px;
      border-radius: 8px;
    }
    .title {
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid $color-grey02;
      @media (max-width: 962px) {
        order: 2;
        padding-bottom: 16px;
        margin-bottom: 0px;
        border-bottom: 1px solid transparent;
        text-align: center;
      }
    }
    .data {
      padding-bottom: 20px;
      color: $color-green01;
      @media (max-width: 962px) {
        order: 1;
        padding-bottom: 8px;
        text-align: center;
      }
    }
    .info {
      white-space: pre-wrap;
      padding-bottom: 55px;
      overflow: hidden;
      @media (max-width: 962px) {
        order: 4;
        padding-bottom: 0px;
      }
      .reactMarkDown {
        text-align: justify;
        strong {
          font-weight: 600 !important;
        }
        p {
          text-indent: 40px;
          @media (max-width: 962px) {
            text-indent: 24px;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          list-style-position: inside;
          text-indent: 40px;
          @media (max-width: 962px) {
            text-indent: 24px;
          }
        }
        ol {
          list-style-position: inside;
          display: flex;
          flex-direction: column;
          text-indent: 40px;
          @media (max-width: 962px) {
            text-indent: 24px;
          }
        }
        a {
          color: $color-blue01;
          text-decoration: underline;
        }
      }
    }
    .image {
      width: 100%;
      aspect-ratio: (1190 / 512);
      max-height: 512px;
      height: 100%;
      border-radius: 10px;
      @media (max-width: 962px) {
        order: 3;
        aspect-ratio: (319 / 200);
        border-radius: 8px;
        margin-bottom: 28px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        @media (max-width: 962px) {
          border-radius: 8px;
        }
      }
    }
  }
  .allNews {
    width: 29.89%;
    height: max-content;
    padding: 30px 20px;
    border-radius: 15px;
    background-color: $color-white01;
    @media (max-width: 1536px) {
      width: 100%;
    }
    @media (max-width: 962px) {
      width: 100%;
      padding: 12px 16px;
      border-radius: 8px;
    }
    .header {
      padding-bottom: 30px;
      @media (max-width: 962px) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $color-grey02;
      }
    }
    &__content {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 962px) {
        gap: 8px;
      }
      .item {
        border-bottom: 1px solid $color-grey02;
        @media (max-width: 962px) {
          border-bottom: 1px solid $color-grey01;
        }
        cursor: pointer;
        .title {
          padding-bottom: 10px;
          @media (max-width: 962px) {
            padding-bottom: 8px;
          }
        }
        .date {
          padding-bottom: 15px;
          color: $color-grey03;
          @media (max-width: 962px) {
            padding-bottom: 12px;
          }
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
    }
  }
}
