@import "../../../../global.scss";

.solutions {
  padding: 50px 30px;
  border-radius: 20px;
  background-color: $color-white01;
  @media (max-width: 962px) {
    padding: 16px 12px;
    border-radius: 8px;
  }
  .title {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-grey01;
    margin-bottom: 50px;
    @media (max-width: 962px) {
      padding-bottom: 16px;
      margin-bottom: 24px;
    }
  }
  .content {
    .row {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      @media (max-width: 1536px) {
        flex-direction: column;
      }
      @media (max-width: 962px) {
        gap: 8px;
        margin-bottom: 8px;
      }
      .cardLeft {
        width: 61.07%;
        aspect-ratio: (1087 / 414);
        @media (max-width: 1536px) {
          width: 100%;
        }
        @media (max-width: 962px) {
          aspect-ratio: (319 / 160);
        }
      }
      .cardRight {
        width: 37.25%;
        aspect-ratio: (663 / 414);
        @media (max-width: 1536px) {
          width: 100%;
          aspect-ratio: (1087 / 414);
        }
        @media (max-width: 962px) {
          aspect-ratio: (319 / 160);
        }
      }
    }
    .cols {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      @media (max-width: 1536px) {
        flex-direction: column;
      }
      @media (max-width: 962px) {
        gap: 8px;
      }
      .colLeft {
        width: 38.54%;
        @media (max-width: 1536px) {
          width: 100%;
          order: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        @media (max-width: 962px) {
          gap: 8px;
        }
        .cardTop {
          margin-bottom: 20px;
          aspect-ratio: (686 / 438);
          @media (max-width: 1536px) {
            width: 100%;
            aspect-ratio: (1087 / 414);
            order: 1;
            margin-bottom: 0px;
          }
          @media (max-width: 962px) {
            aspect-ratio: (319 / 160);
            margin-bottom: 8px;
          }
        }
        .cardBottom {
          aspect-ratio: (686 / 931);
          @media (max-width: 1536px) {
            width: 100%;
            aspect-ratio: (1087 / 414);
            order: 0;
          }
          @media (max-width: 962px) {
            aspect-ratio: (319 / 160);
          }
        }
      }
      .colRight {
        width: 59.78%;
        @media (max-width: 1536px) {
          width: 100%;
          order: 0;
        }
        .cardTop {
          margin-bottom: 20px;
          aspect-ratio: (1064 / 852);
          @media (max-width: 1536px) {
            width: 100%;
            aspect-ratio: (1087 / 414);
          }
          @media (max-width: 962px) {
            width: 100%;
            aspect-ratio: (319 / 160);
            margin-bottom: 8px;
          }
        }
        .cardBottom {
          aspect-ratio: (1064 / 517);
          @media (max-width: 1536px) {
            aspect-ratio: (1087 / 414);
            width: 100%;
          }
          @media (max-width: 962px) {
            aspect-ratio: (319 / 160);
          }
        }
      }
    }
  }
}
