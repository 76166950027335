@import "../../../global.scss";

.mediaCard {
  border-bottom: 1px solid $color-grey02;
  display: flex;
  justify-content: space-between;
  gap: 45px;
  cursor: pointer;
  @media (max-width: 1536px) {
    padding-top: 12px;
    gap: 12px;
  }
  @media (max-width: 962px) {
    flex-direction: column;
  }
  &.active {
    .content {
      .image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .date {
    white-space: nowrap;
    width: 165px;
    @media (max-width: 962px) {
      font-size: 13px !important;
      line-height: 15.6px !important;
    }
  }
  .content {
    width: 74.16%;
    padding-right: 58px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 45px;
    @media (max-width: 1536px) {
      width: calc(100% - 150px);
      padding-right: 0px;
      gap: 16px;
    }
    @media (max-width: 962px) {
      width: 100%;
      padding-right: 0px;
      gap: 12px;
      margin-bottom: 12px;
      flex-direction: column;
    }
    .image {
      width: 39.62%;
      max-height: max-content;
      aspect-ratio: (500 / 282);
      border-radius: 12px;
      overflow: hidden;
      background-color: $color-white01;
      @media (max-width: 962px) {
        width: 100%;
        aspect-ratio: (319 / 200);
        border-radius: 8px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
        transition: all 0.5s;
        @media (max-width: 962px) {
          border-radius: 8px;
        }
      }
    }
    .info {
      width: 56.81%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      @media (max-width: 962px) {
        width: 100%;
      }
      .wrap {
        .title {
          padding-bottom: 27px;
          @media (max-width: 962px) {
            width: 100%;
            font-size: 13px !important;
            line-height: 15.6px !important;
          }
        }
        @media (max-width: 962px) {
          font-size: 11px !important;
          line-height: 13.2px !important;
          font-weight: 300 !important;
        }
      }
    }
    .button {
      @media (max-width: 1536px) {
        display: none;
      }
      @media (max-width: 962px) {
        display: block;
      }
    }
  }
}
