@import "../../../../../global.scss";

.card {
  width: 100%;
  height: 100%;
  padding: 25px;
  border-radius: 12px;
  background-color: $color-white01;
  cursor: pointer;
  user-select: none;
  @media (max-width: 962px) {
    padding: 12px;
  }
  &.disableCursorPointer {
    cursor: auto;
  }
  a {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    @media (max-width: 962px) {
      gap: 16px;
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .logo {
        height: 40px;
        max-width: 271px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        .title {
          padding-bottom: 15px;
          @media (max-width: 962px) {
            padding-bottom: 8px;
            font-size: 16px !important;
            line-height: 19.2px !important;
          }
        }
        .description {
          @media (max-width: 962px) {
            font-size: 13px !important;
            line-height: 15.6px !important;
          }
        }
      }
    }
  }
}
