@import "../../../../global.scss";

.hero {
  height: calc(100vh - 166px);
  padding: 30px;
  border-radius: 30px;
  background-color: $color-white01;
  position: relative;
  @media (max-width: 962px) {
    position: static;
    height: calc(100vh - 82px);
    padding: 12px 16px;
    border-radius: 8px;
  }
  .video {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 20px;
    @media (max-width: 962px) {
      //height: calc(100% - 60px);
      border-radius: 8px;
      margin-bottom: 8px;
    }
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.37);
      @media (max-width: 962px) {
        border-radius: 8px;
      }
    }
    &::after {
      position: absolute;
      content: "Энергия – это Мы";
      left: 30px;
      bottom: 202px;
      width: 100%;
      font-size: 100px;
      line-height: 100px;
      font-weight: 400;
      color: $color-white01;
      @media (max-width: 1536px) {
        font-size: 50px;
        line-height: 50px;
      }
      @media (max-width: 962px) {
        left: 12px;
        bottom: 32px;
        left: 12px;
        font-size: 32px;
        line-height: 38.4px;
      }
    }
    video {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
      @media (max-width: 962px) {
        border-radius: 8px;
      }
    }
  }
  .info {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 823px;
    width: 100%;
    padding: 20px 20px 30px 30px;
    border-radius: 20px;
    background-color: $color-white01;
    @media (max-width: 962px) {
      position: static;
      max-width: unset;
      padding: 0px;
      border-radius: 8px;
      //height: 60px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      bottom: 30px;
      width: 20px;
      height: 20px;
      background: radial-gradient(
        circle at 100% 0,
        transparent 20px,
        $color-white01 20px
      );
      @media (max-width: 962px) {
        display: none;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 30px;
      top: -20px;
      width: 20px;
      height: 20px;
      background: radial-gradient(
        circle at 100% 0,
        transparent 20px,
        $color-white01 20px
      );
      @media (max-width: 962px) {
        display: none;
      }
    }
    .content {
      padding: 40px 30px;
      border-radius: 15px;
      color: $color-white01;
      background-color: $color-blue02;
      @media (max-width: 962px) {
        padding: 12px;
        border-radius: 8px;
      }
    }
  }
}
