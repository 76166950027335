@import "../../../../../global.scss";

.card {
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $color-grey01;
  color: $color-black01;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  user-select: none;
  transition: all 0.5s;
  overflow: hidden;
  position: relative;
  @media (max-width: 962px) {
    padding: 12px;
    border-radius: 8px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: $color-black01;
    transition: all 0.5s;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    .content {
      .mainInfo {
        opacity: 0;
      }
      .hoverInfo {
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
  &.withImg {
    color: $color-white01;
    .content {
      .title {
        border-bottom: 1px solid $color-white01;
      }
    }
  }
  .content {
    position: relative;
    .mainInfo {
      opacity: 1;
      transition: all 0.5s;
      .title {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-grey02;
      }
      .description {
        @media (max-width: 962px) {
          font-size: 13px;
          line-height: 15.6px;
        }
      }
    }
    .hoverInfo {
      position: absolute;
      bottom: 0px;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      //visibility: hidden;
      transform: translateY(calc(100% + 30px));
      transition: all 0.5s;
      @media (max-width: 962px) {
        gap: 8px;
      }
      .paragraph {
        color: $color-white01;
      }
    }
  }
}
