$color-white01: #ffffff;
$color-grey01: #e7ecf0;
$color-grey02: #c6cbce;
$color-grey03: #777d83;
$color-grey04: #52575c;
$color-black01: #121b24;
$color-navy01: #233b77;
$color-blue01: #02b1e1;
$color-blue02: #29377f;
$color-green01: #14993a;

.heading {
  font-weight: 400;
  &.huge {
    font-size: 100px;
    line-height: 100px;
    @media (max-width: 1680px) {
      font-size: 80px;
      line-height: 80px;
    }
    @media (max-width: 962px) {
      font-size: 32px;
      line-height: 38.4px;
    }
  }
  &.large {
    font-size: 64px;
    line-height: 76.8px;
    @media (max-width: 1680px) {
      font-size: 51.2px;
      line-height: 61.44px;
    }
    @media (max-width: 962px) {
      font-size: 24px;
      line-height: 28.8px;
    }
    &.light {
      font-weight: 300;
    }
  }
  &.medium {
    font-size: 42px;
    line-height: 50px;
    @media (max-width: 1680px) {
      font-size: 33.6px;
      line-height: 40x;
    }
    @media (max-width: 962px) {
      font-size: 16px;
      line-height: 19.2px;
    }
    &.light {
      font-weight: 300;
    }
  }
}

.text {
  font-weight: 400;
  &.large {
    font-size: 32px;
    line-height: 38px;
    @media (max-width: 1680px) {
      font-size: 26.6px;
      line-height: 30.4px;
    }
    @media (max-width: 962px) {
      font-size: 24px;
      line-height: 28.8px;
    }
    &.light {
      font-weight: 300;
    }
  }
  &.big {
    font-size: 26px;
    line-height: 31.2px;
    @media (max-width: 1680px) {
      font-size: 20.8px;
      line-height: 24.96px;
    }
    @media (max-width: 962px) {
      font-size: 11px;
      line-height: 13.2px;
    }
  }
  &.medium {
    font-size: 24px;
    line-height: 28.8px;
    @media (max-width: 1680px) {
      font-size: 19.2px;
      line-height: 23px;
    }
    @media (max-width: 962px) {
      font-size: 11px;
      line-height: 13.2px;
    }
    &.light {
      font-weight: 300;
    }
  }
  &.regular {
    font-size: 20px;
    line-height: 24px;
    @media (max-width: 1680px) {
      font-size: 16px;
      line-height: 19.2px;
    }
    @media (max-width: 962px) {
      font-size: 11px;
      line-height: 13.2px;
    }
    &.light {
      font-weight: 300;
    }
  }
  &.small {
    font-size: 18px;
    line-height: 21.6px;
    @media (max-width: 1680px) {
      font-size: 14.4px;
      line-height: 17.3px;
    }
    @media (max-width: 962px) {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
  &.small_medium {
    font-size: 16px;
    line-height: 19.2px;
    @media (max-width: 1680px) {
      font-size: 12.8px;
      line-height: 15.36px;
    }
    @media (max-width: 962px) {
      font-size: 9px;
      line-height: 10.8px;
    }
  }
}

.container {
  margin: 0 auto;
  @media (max-width: 962px) {
    padding-left: 16px;
    padding-right: 16px;
    background-color: $color-grey01;
  }
}
