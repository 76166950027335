@import "../../global.scss";

.header {
  .wrap {
    padding: 31px 30px;
    border-radius: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    background-color: $color-white01;
    @media (max-width: 962px) {
      padding: 16px;
      border-radius: 0px;
      margin-bottom: 16px;
      position: relative;
      z-index: 900;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      gap: 30px;
      @media (max-width: 962px) {
        display: none;
      }
      .item {
        color: $color-grey03;
        cursor: pointer;
        &:hover {
          color: $color-black01;
        }
      }
    }
    .phone {
      color: $color-black01;
      @media (max-width: 1366px) {
        display: none;
      }
    }
    .menuIcon {
      display: none;
      @media (max-width: 962px) {
        display: block;
      }
      .barItem {
        background: $color-grey04;
        display: block;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.2s ease all;
        transition: 0.2s ease all;
        height: 1px;
        width: 24px;
        margin-bottom: 5px;
      }
      &.active {
        .barTop {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          -webkit-transform-origin: 15% 15%;
          transform-origin: 15% 15%;
        }
        .barMid {
          opacity: 0;
        }
        .barBot {
          -webkit-transform: rotate(45deg);
          transform: rotate(-45deg);
          -webkit-transform-origin: 15% 95%;
          transform-origin: 15% 95%;
        }
      }
    }
  }
  .mobileMenu {
    height: calc(100vh - 56px);
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 56px;
    transform: translateY(calc(-100% - 248px));
    background-color: $color-blue02;
    transition: all 0.5s;
    &.active {
      transform: translateY(0);
    }
    .mobileContent {
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__item {
        color: $color-grey02;
      }
    }
  }
}
