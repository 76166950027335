@import "../../../../global.scss";

.about {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1536px) {
    gap: 16px;
  }
  .mainSection {
    aspect-ratio: (1840 / 1020);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    background-color: $color-white01;
    @media (max-width: 1536px) {
      padding: 0px;
      flex-direction: column;
      background-color: $color-grey01;
    }
    .image {
      position: relative;
      width: calc(50% - 30px);
      max-height: 960px;
      border-radius: 15px;
      @media (max-width: 1536px) {
        max-height: 450px;
        width: 100%;
        padding: 12px;
        border-radius: 12px;
        margin-bottom: 16px;
        background-color: $color-white01;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        object-fit: cover;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: -20px;
        bottom: -30px;
        width: 20px;
        height: 20px;
        background: radial-gradient(
          circle at 0 0,
          transparent 20px,
          $color-grey01 20px
        );
        @media (max-width: 1536px) {
          display: none;
        }
      }
    }
    .info {
      position: relative;
      width: calc(50% + 10px);
      height: max-content;
      aspect-ratio: (900 / 468);
      padding: 30px 30px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $color-blue02;
      @media (max-width: 1536px) {
        width: 100%;
        padding: 40px;
        aspect-ratio: unset;
      }
      @media (max-width: 962px) {
        padding: 16px;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: -30px;
        bottom: -30px;
        width: 20px;
        height: 20px;
        background: radial-gradient(
          circle at 0 0,
          transparent 20px,
          $color-grey01 20px
        );
        @media (max-width: 1536px) {
          display: none;
        }
      }
      .title {
        padding-bottom: 30px;
        color: $color-green01;
        @media (max-width: 1536px) {
          padding-bottom: 16px;
        }
        @media (max-width: 962px) {
          padding-bottom: 8px;
        }
      }
      .description {
        color: $color-white01;
        @media (max-width: 1536px) {
          max-width: 820px;
          width: 100%;
        }
      }
    }
  }

  .wrap {
    position: relative;
    width: 100%;
    aspect-ratio: (1840 /355);
    @media (max-width: 962px) {
      display: none;
    }
    .cardsSection {
      position: absolute;
      bottom: 0;
      display: flex;
      @media (max-width: 1536px) {
        position: static;
        flex-direction: column;
      }
      .leftCards {
        width: calc(50% - 10px);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        @media (max-width: 1536px) {
          width: 100%;
          margin-bottom: 20px;
        }
        @media (max-width: 962px) {
          width: 100%;
          margin-bottom: 8px;
          gap: 8px;
          flex-direction: column;
          align-items: center;
        }
        .card1 {
          width: 50%;
          aspect-ratio: (445 / 355);
          @media (max-width: 1536px) {
            width: calc(50% - 4px);
            max-height: 355px;
            aspect-ratio: (217 / 139);
          }
          @media (max-width: 962px) {
            max-height: 200px;
            width: 100%;
          }
        }
        .card2 {
          width: 50%;
          aspect-ratio: (445 / 355);
          @media (max-width: 1536px) {
            width: calc(50% - 4px);
            max-height: 355px;
            aspect-ratio: (217 / 139);
          }
          @media (max-width: 962px) {
            max-height: 200px;
            width: 100%;
          }
        }
      }
      .rightCards {
        width: calc(50% + 10px);
        padding-left: 20px;
        padding-top: 20px;
        border-top-left-radius: 20px;
        background-color: $color-grey01;
        @media (max-width: 1536px) {
          width: 100%;
          padding: 0px;
        }
        &__content {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 20px;
          @media (max-width: 962px) {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          .colLeft {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media (max-width: 1536px) {
              width: calc(50% - 4px);
            }
            @media (max-width: 962px) {
              width: 100%;
              align-items: center;
              gap: 8px;
            }
            .card3 {
              width: 100%;
              aspect-ratio: (445 / 485);
              @media (max-width: 1536px) {
                width: 100%;
                max-height: 355px;
                aspect-ratio: (217 / 139);
              }
              @media (max-width: 962px) {
                max-height: 200px;
              }
            }
            .card4 {
              width: 100%;
              aspect-ratio: (445 / 355);
              @media (max-width: 1536px) {
                width: 100%;
                max-height: 355px;
                aspect-ratio: (217 / 139);
              }
              @media (max-width: 962px) {
                max-height: 200px;
              }
            }
          }
          .colRight {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media (max-width: 1536px) {
              width: calc(50% - 4px);
            }
            @media (max-width: 962px) {
              width: 100%;
              align-items: center;
              gap: 8px;
            }
            .card5 {
              width: 100%;
              aspect-ratio: (445 / 355);
              @media (max-width: 1536px) {
                width: 100%;
                max-height: 355px;
                aspect-ratio: (217 / 139);
              }
              @media (max-width: 962px) {
                max-height: 200px;
              }
            }
            .card6 {
              width: 100%;
              aspect-ratio: (445 / 485);
              @media (max-width: 1536px) {
                width: 100%;
                max-height: 355px;
                aspect-ratio: (217 / 139);
              }
              @media (max-width: 962px) {
                max-height: 200px;
              }
            }
          }
        }
      }
    }
  }
  .mobileCards {
    display: none;
    @media (max-width: 962px) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .mobileCard {
        width: 100%;
        height: 163px;
      }
    }
  }
}
