@import "../../../../global.scss";

.groupOfCompanies {
  padding: 70px 30px 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $color-white01;
  @media (max-width: 962px) {
    padding: 16px 12px;
    border-radius: 8px;
  }
  .title {
    padding-bottom: 40px;
    color: $color-green01;
    @media (max-width: 962px) {
      padding-bottom: 8px;
    }
  }
  &__info {
    max-width: 1094px;
    width: 100%;
    padding-bottom: 100px;
    @media (max-width: 1366px) {
      padding-bottom: 40px;
    }
    @media (max-width: 962px) {
      padding-right: 8px;
      padding-bottom: 16px;
      padding-left: 8px;
      font-weight: 300 !important;
    }
  }
  .slider {
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: 962px) {
      display: none;
    }
  }
  .mobileCards {
    display: none;
    @media (max-width: 962px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .card {
    max-width: 420px;
    width: 100%;
    max-height: 250px;
    aspect-ratio: (420 / 200);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    background-color: $color-grey01;
    @media (max-width: 962px) {
      max-width: unset;
      max-height: 163px;
      aspect-ratio: unset;
      padding: 12px;
      border-radius: 8px;
    }
    .info {
      padding-bottom: 10px;
      border-bottom: 1px solid $color-grey02;
      color: $color-green01;
      @media (max-width: 962px) {
        margin-bottom: 10px;
        font-size: 32px !important;
        line-height: 38.4px !important;
      }
    }
    .description {
      color: $color-black01;
      font-size: 16px !important;
      line-height: 19.2px !important;
      font-weight: 300 !important;
    }
  }
}
