@import "../../../global.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 962px) {
    gap: 20px;
  }
}
