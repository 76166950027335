@import "../../../../global.scss";

.partners {
  padding: 50px 20px;
  border-radius: 20px;
  background-color: $color-white01;
  @media (max-width: 962px) {
    padding: 16px 12px;
  }
  .title {
    padding-bottom: 20px;
    margin-bottom: 70px;
    border-bottom: 1px solid $color-grey02;
    @media (max-width: 962px) {
      padding-bottom: 16px;
      margin-bottom: 8px;
    }
  }
  .slider {
    .card {
      max-width: 420.5px;
      width: 100%;
      aspect-ratio: (420.5 / 250);
      padding: 47px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-grey01;
      @media (max-width: 962px) {
        aspect-ratio: (101 / 66);
        padding: 23px 12px;
        border-radius: 4px;
      }
    }
  }
}
